import { GRID_ELEMENT_SIZE } from "../constants";
import { LocationType } from "../redux/types";

import { ReactComponent as LoadingDock } from '../resources/noun-loading-dock-5428957.svg';
import { ReactComponent as Office } from '../resources/noun-office-4116641.svg';
import { ReactComponent as Vault } from '../resources/noun-vault-3353889.svg';

type Props = Readonly<{
    type: LocationType
    x: number,
    y: number
}>;

export const LocationViews = ({ type, x, y }: Props) => {
    if (type === LocationType.LoadingDock) {
        return <LoadingDock x={x * GRID_ELEMENT_SIZE} y={y * GRID_ELEMENT_SIZE} width={GRID_ELEMENT_SIZE} height={GRID_ELEMENT_SIZE} fill="green"/>
    } else if (type === LocationType.Office) {
        return <Office x={x * GRID_ELEMENT_SIZE} y={y * GRID_ELEMENT_SIZE} width={GRID_ELEMENT_SIZE} height={GRID_ELEMENT_SIZE} fill="green"/>
    } else if (type === LocationType.Vault) {
        return <Vault x={x * GRID_ELEMENT_SIZE} y={y * GRID_ELEMENT_SIZE} width={GRID_ELEMENT_SIZE} height={GRID_ELEMENT_SIZE} fill="red"/>
    }

    return <g/>;
};