import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import { ConveyorBelt } from './ConveyorBelt';
import { store } from "./redux/store";

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <ConveyorBelt/>
      </Provider>
    </div>
  );
}

export default App;
