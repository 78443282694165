import { GRID_ELEMENT_SIZE } from "../constants";
import { BeltItem, ItemType } from "../redux/types";

// This import style works as an artifact of using the Create React App, if
// we need to eject, look into https://react-svgr.com/ with webpack
import { ReactComponent as OilCan } from '../resources/noun-oil-can-5330488.svg';
import { ReactComponent as Extinguisher } from '../resources/noun-emergency-2724400.svg';
import { ReactComponent as Box } from '../resources/noun-box-17864.svg';

export const BeltItemView = ({ item }: {item: BeltItem }) => {
    if (!item.isUsed) {
        if (item.itemType === ItemType.Oil) {
            return <OilCan x={item.x * GRID_ELEMENT_SIZE} y={item.y * GRID_ELEMENT_SIZE} width={GRID_ELEMENT_SIZE} height={GRID_ELEMENT_SIZE} fill="blue" />;
        } else if (item.itemType === ItemType.Extinguisher) {
            return <Extinguisher x={item.x * GRID_ELEMENT_SIZE} y={item.y * GRID_ELEMENT_SIZE} width={GRID_ELEMENT_SIZE} height={GRID_ELEMENT_SIZE} fill="blue" />;
        } else if (item.itemType === ItemType.Box) {
            return <Box x={item.x * GRID_ELEMENT_SIZE} y={item.y * GRID_ELEMENT_SIZE} width={GRID_ELEMENT_SIZE} height={GRID_ELEMENT_SIZE} fill="red" />;
        }
    }

    return <g/>;
}