import { configureStore } from "@reduxjs/toolkit";
import configurationReducer from "./configurationReducer";
import hazardsReducer from "./hazardsReducer";
import itemsReducer from "./itemsReducer";

export const store = configureStore({
    reducer: {
        config: configurationReducer,
        hazards: hazardsReducer,
        items: itemsReducer,
    }
});