import { BeltGridNode, RotationType, SubType } from "./types";

// Flattens rotation type for half junctions, no-op for full junctions.
export const getActualRotation = (beltNode: BeltGridNode, rotation: RotationType) => {
    let actualRotation = rotation;

    if (beltNode.lockedRotation) {
        actualRotation = beltNode.lockedRotation
    } else if (beltNode.subType === SubType.Top) {
        if (rotation % 2 === 0) {
            actualRotation = RotationType.TopLeft;
        } else {
            actualRotation = RotationType.TopRight;
        }
    } else if (beltNode.subType === SubType.Bottom) {
        if (rotation % 2 === 0) {
            actualRotation = RotationType.BottomLeft;
        } else {
            actualRotation = RotationType.BottomRight;
        }
    } else if (beltNode.subType === SubType.Right) {
        if (rotation % 2 === 0) {
            actualRotation = RotationType.TopRight;
        } else {
            actualRotation = RotationType.BottomRight;
        }
    } else if (beltNode.subType === SubType.Left) {
        if (rotation % 2 === 0) {
            actualRotation = RotationType.TopLeft;
        } else {
            actualRotation = RotationType.BottomLeft;
        }
    }

    return actualRotation;
}