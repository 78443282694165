import { createSlice } from '@reduxjs/toolkit';
import { BeltHazard, HazardType } from './types';

const initialState: BeltHazard[] = [
    {
        x: 7,
        y: 7,
        hazardType: HazardType.Broken,
    },
    {
        x: 5,
        y: 3,
        hazardType: HazardType.Fire,
    }
];

const hazardsSlice = createSlice({
    name: 'hazards',
    initialState,
    reducers: {
        fixHazard(state: BeltHazard[], action: { type: string, payload: { x: number, y: number }}) {
            const hazard = state.find(hazard => hazard.x === action.payload.x && hazard.y === action.payload.y);            
            if (hazard) {
                hazard.isFixed = true;
            } else {
                console.warn(`WARNING - Attempted to update hazard at unknown position: X:${action.payload.x},Y:${action.payload.y}`)
            }
        }
    }
});

export const { fixHazard } = hazardsSlice.actions;
export default hazardsSlice.reducer;