
export enum HazardType {
    None = 0,
    Broken = 1,
    Fire = 2
}

export enum ItemType {
    None = 0,
    Oil = 1,
    Extinguisher = 2,
    Box = 3
}

export enum BeltType {
    Empty,
    Vertical,
    Horizontal,
    Junction,
    TerminusLeft,
    TerminusRight,
    TerminusTop,
    TerminusBottom,
    ThreeWay,
    BrokenVertical,
    BrokenJunction,
    VerticalSpecial,
    Location
};

export enum SubType {
    Top,
    Bottom,
    Left,
    Right
};

export enum LocationType {
    Office = 1,
    Vault = 2,
    LoadingDock = 3
}

export enum RotationType {
    TopRight = 0,
    TopLeft = 1,
    BottomLeft = 2,
    BottomRight = 3,
}

export type BeltGridNode = {
    beltType: BeltType;
    subType?: SubType;
    lockedRotation?: RotationType;
    location?: LocationType;
};

export type BeltHazard = {
    x: number;
    y: number;
    hazardType: HazardType;
    isFixed?: boolean;
};

export type BeltItem = {
    x: number;
    y: number;
    itemType: ItemType;
    previousX?: number;
    previousY?: number;
    hazardFix?: HazardType;
    isUsed?: boolean;
};

export type ConveyorBeltState = {
    config: Configuration;
    hazards: BeltHazard[];
    items: BeltItem[];
};

export type Configuration = {
    isActive: boolean;
    isDirectionForward: boolean;
    intervalTimeMs: number;
    rotation: RotationType;
    showGrid: boolean;
}