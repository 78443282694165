import { createSlice } from "@reduxjs/toolkit";
import { BeltItem, ItemType, HazardType } from "./types";

const initialState: BeltItem[] = [
    {
        x: 7,
        y: 1,
        hazardFix: HazardType.Broken,
        itemType: ItemType.Oil        
    },
    {
        x: 2,
        y: 3,
        hazardFix: HazardType.Fire,
        itemType: ItemType.Extinguisher
    },
    {
        x: 2,
        y: 5,
        itemType: ItemType.Box
    }
];

const itemsSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {
        updateItem(state: BeltItem[], action: { type: string, payload: BeltItem}) {
            const item = state.find(item => action.payload.itemType === item.itemType);
            if (item) {
                item.x = action.payload.x;
                item.y = action.payload.y;
                item.previousX = action.payload.previousX;
                item.previousY = action.payload.previousY;
                item.isUsed = action.payload.isUsed;
            }
        },
        resetItems() {
            return initialState;
        }
    }
});

export const { updateItem, resetItems } = itemsSlice.actions;
export default itemsSlice.reducer;