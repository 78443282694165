import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GRID_ELEMENT_SIZE, BeltGrid } from './constants';
import { ConveyorBeltState, BeltType, BeltItem, RotationType, BeltHazard, BeltGridNode, SubType } from './redux/types';
import { configDirection, configToggleIsActive, configRotation, configToggleGrid } from './redux/configurationReducer';
import { fixHazard } from './redux/hazardsReducer';
import { resetItems, updateItem } from './redux/itemsReducer';
import { getActualRotation } from './redux/utilities';

import { BeltItemView } from './controls/BeltItemViews';
import { BeltHazardView } from './controls/BeltHazardViews';
import { LocationViews } from './controls/LocationViews';

const reduceItemPosition = (currentItem: BeltItem, newXPosition: number, newYPosition: number, grid: BeltGridNode[][], rotation: RotationType, hazards: BeltHazard[], updateHazard: (x: number, y: number) => void): BeltItem => {
    let updatedItem = {
        ...currentItem,        
        x: newXPosition,
        y: newYPosition,
        previousX: currentItem.x,
        previousY: currentItem.y       
    };
    const targetHazard = hazards.find(hazard => hazard.x === newXPosition && hazard.y === newYPosition && !hazard.isFixed);

    if (newYPosition >= grid.length || newYPosition < 0 || newXPosition >= grid[newYPosition].length || newXPosition < 0) {
        // Item would be in a position outside the grid, treat this as a no-op.
        return currentItem;
    }

    const newNode = grid[newYPosition][newXPosition];

    // TODO: Can determine "win" condition here by checking whether this is a location

    if (newNode.beltType === BeltType.Empty || newNode.beltType === BeltType.BrokenJunction || newNode.beltType === BeltType.BrokenVertical) {
        // Cannot move an item into an empty node or broken ones
        return currentItem;
    } else if (newNode.beltType === BeltType.Junction) {
        const actualRotation = getActualRotation(newNode, rotation);

        if (
            (newYPosition - currentItem.y > 0 && (actualRotation === RotationType.BottomLeft || actualRotation === RotationType.BottomRight)) ||
            (newYPosition - currentItem.y < 0 && (actualRotation === RotationType.TopLeft || actualRotation === RotationType.TopRight)) ||
            (newXPosition - currentItem.x > 0 && (actualRotation === RotationType.BottomRight || actualRotation === RotationType.TopRight)) ||
            (newXPosition - currentItem.x < 0 && (actualRotation === RotationType.BottomLeft || actualRotation === RotationType.TopLeft))
            ) {
            return currentItem;
        }    
    } else if (targetHazard) {        
        if (currentItem.hazardFix === targetHazard.hazardType) {
            updateHazard(newXPosition, newYPosition);
            updatedItem = {
                ...updatedItem,
                isUsed: true
            };
        } else {
            return currentItem;
        }

    } 
    
    if (currentItem.x === newXPosition && currentItem.y === newYPosition) {
        // We haven't moved, so don't return the updated item.        
        return currentItem;
    }

    // If we made it this far, it's safe to update the item.
    return updatedItem;
};

export const ConveyorBelt = () => {
    const dispatch = useDispatch();
    const config = useSelector((state: ConveyorBeltState) => state.config);
    const hazards = useSelector((state: ConveyorBeltState) => state.hazards);
    const beltItems = useSelector((state: ConveyorBeltState) => state.items);

    const fixHazardDispatch = (x: number, y: number) => dispatch(fixHazard({ x: x, y: y}));

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> | null = null;

        if (config.isActive) {
            interval = setInterval(() => {
                for (let i = 0; i < beltItems.length; i++) {
                    const currentItem: BeltItem = beltItems[i];
                    const directionSign = config.isDirectionForward ? 1 : -1;
                    
                    // Validate the item's position
                    if (currentItem.y >= BeltGrid.length ||
                        currentItem.x >= BeltGrid[currentItem.y].length) {
                            console.error(`ERROR - Item is in an invalid position X:${currentItem.x},Y:${currentItem.y}`);
                            continue;
                    }

                    const currentGridNode = BeltGrid[currentItem.y][currentItem.x];

                    if (currentGridNode.beltType === BeltType.Vertical) {
                        const newYPosition = currentItem.y + directionSign;
                        dispatch(updateItem(reduceItemPosition(currentItem, currentItem.x, newYPosition, BeltGrid, config.rotation, hazards, fixHazardDispatch)));
                    } else if (currentGridNode.beltType === BeltType.Horizontal) {
                        const newXPosition = currentItem.x + directionSign;
                        dispatch(updateItem(reduceItemPosition(currentItem, newXPosition, currentItem.y, BeltGrid, config.rotation, hazards, fixHazardDispatch)));
                    } else if (currentGridNode.beltType === BeltType.ThreeWay) {
                        let newYPosition = currentItem.y;

                        if (currentGridNode.subType === SubType.Left) {
                            newYPosition = currentItem.y + directionSign;                                
                        }

                        dispatch(updateItem(reduceItemPosition(currentItem, currentItem.x, newYPosition, BeltGrid, config.rotation, hazards, fixHazardDispatch)));                    
                    } else if (currentGridNode.beltType === BeltType.TerminusTop) {                        
                        const newYPosition = config.isDirectionForward ? currentItem.y + directionSign : currentItem.y;
                        dispatch(updateItem(reduceItemPosition(currentItem, currentItem.x, newYPosition, BeltGrid, config.rotation, hazards, fixHazardDispatch)));
                    } else if (currentGridNode.beltType === BeltType.TerminusRight) {
                        const newXPosition = config.isDirectionForward ? currentItem.x : currentItem.x + directionSign ;
                        dispatch(updateItem(reduceItemPosition(currentItem, newXPosition, currentItem.y, BeltGrid, config.rotation, hazards, fixHazardDispatch)));
                    } else if (currentGridNode.beltType === BeltType.TerminusLeft) {
                        const newXPosition = config.isDirectionForward ? currentItem.x : currentItem.x + directionSign;
                        dispatch(updateItem(reduceItemPosition(currentItem, newXPosition, currentItem.y, BeltGrid, config.rotation, hazards, fixHazardDispatch)));                    
                    } else if (currentGridNode.beltType === BeltType.TerminusBottom) {                        
                        const newYPosition = config.isDirectionForward ? currentItem.y : currentItem.y + directionSign;
                        dispatch(updateItem(reduceItemPosition(currentItem, currentItem.x, newYPosition, BeltGrid, config.rotation, hazards, fixHazardDispatch)));
                    } else if (currentGridNode.beltType === BeltType.Junction) {
                        let newXPosition = currentItem.x, newYPosition = currentItem.y;
                        const actualRotation = getActualRotation(currentGridNode, config.rotation);

                        switch (actualRotation) {
                            case RotationType.TopRight:
                                if (config.isDirectionForward) {
                                    newXPosition = currentItem.x + directionSign;
                                } else {
                                    newYPosition = currentItem.y + directionSign;
                                }
                                break;
                            case RotationType.BottomRight:
                                // Bottom right is nuanced - since belts move down and to the right by default,
                                // both are valid. If we previously moved horizontally, prefer vertical, otherwise
                                // default to horizontal.
                                if (config.isDirectionForward) {
                                    if (currentItem.previousX !== currentItem.x) {   
                                        newYPosition = currentItem.y + directionSign;
                                    } else {
                                        newXPosition = currentItem.x + directionSign;
                                    }
                                }
                                break;

                            case RotationType.BottomLeft:
                                if (config.isDirectionForward) {
                                    newYPosition = currentItem.y + directionSign;
                                } else {
                                    newXPosition = currentItem.x + directionSign;
                                }
                                break;
                            case RotationType.TopLeft:
                                // Top left has the same nuance as bottom right.
                                if (!config.isDirectionForward) {
                                    if (currentItem.previousX !== currentItem.x) {
                                        newYPosition = currentItem.y + directionSign;
                                    } else {
                                        newXPosition = currentItem.x + directionSign;
                                    }
                                }
                                break;
                            }                            

                        dispatch(updateItem(reduceItemPosition(currentItem, newXPosition, newYPosition, BeltGrid, config.rotation, hazards, fixHazardDispatch)));
                    }
                }
            }, config.intervalTimeMs);
        } else if (interval) {
            clearInterval(interval);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }

    }, [config, beltItems, dispatch, fixHazardDispatch, hazards]);

    const renderBeltGrid = (rotation: number) => {
        let elements: JSX.Element[] = [];

        // It may feel counter-intuitive that i maps to y and j maps to x.
        // The intent is for the double array to visually represent the
        // grid to a human to make it easier to to put it together.
        for (let i = 0; i < BeltGrid.length; i++) {
            for (let j = 0; j < BeltGrid[i].length; j++) {
                if (config.showGrid) {
                    elements.push(
                        <rect
                            className="conveyor-grid"
                            key={`debug-grid-${j}, ${i}`}
                            x={j * GRID_ELEMENT_SIZE}
                            y={i * GRID_ELEMENT_SIZE}
                            width={GRID_ELEMENT_SIZE}
                            height={GRID_ELEMENT_SIZE}                            
                        />
                    );
                }

                const currentNode = BeltGrid[i][j];
                switch (currentNode.beltType) {
                    case BeltType.Vertical:
                        elements.push(                            
                            <line
                                className="conveyor-belt"
                                key={`conveyor-item-${j}-${i}`}
                                x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                y1={i * GRID_ELEMENT_SIZE}
                                y2={i * GRID_ELEMENT_SIZE + GRID_ELEMENT_SIZE}
                            />
                        )
                        break;
                    case BeltType.Horizontal:
                        elements.push(
                            <line
                                className="conveyor-belt"
                                key={`conveyor-item-${j}-${i}`}
                                x1={j * GRID_ELEMENT_SIZE}
                                x2={j * GRID_ELEMENT_SIZE + GRID_ELEMENT_SIZE}
                                y1={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE/ 2)}
                                y2={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE/ 2)}
                            />
                        )
                        break;
                    case BeltType.TerminusTop:
                        elements.push(
                            <g key={`conveyor-item-${j}-${i}`}>
                                <line
                                    className="conveyor-belt"                                
                                    x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 4)}
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE * 0.75)}
                                    y1={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y2={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                />
                                <line
                                    className='conveyor-belt'
                                    x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y1={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y2={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE)}
                                />
                            </g>
                        );
                        break;
                    case BeltType.TerminusRight:
                        elements.push(
                            <g key={`conveyor-item-${j}-${i}`}>
                                <line
                                    className="conveyor-belt"                                
                                    x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y1={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 4)}
                                    y2={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE * 0.75)}
                                />
                                <line
                                    className='conveyor-belt'
                                    x1={(j * GRID_ELEMENT_SIZE)}
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y1={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y2={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                />
                            </g>
                        );
                        break;
                    case BeltType.TerminusLeft:
                        elements.push(
                            <g key={`conveyor-item-${j}-${i}`}>
                                <line
                                    className="conveyor-belt"                                
                                    x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y1={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 4)}
                                    y2={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE * 0.75)}
                                />
                                <line
                                    className='conveyor-belt'
                                    x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE)}
                                    y1={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y2={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                />
                            </g>
                        );
                        break;
                    case BeltType.TerminusBottom:
                        elements.push(
                            <g key={`conveyor-item-${j}-${i}`}>
                                <line
                                    className="conveyor-belt"                                
                                    x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 4)}
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE * 0.75)}
                                    y1={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y2={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                />
                                <line
                                    className='conveyor-belt'
                                    x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                    y1={(i * GRID_ELEMENT_SIZE)}
                                    y2={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                                />
                            </g>
                        );
                        break;

                    case BeltType.ThreeWay:                        
                        switch(currentNode.subType) {
                            case SubType.Left: 
                                elements.push(
                                    <g key={`conveyor-item-${j}-${i}`}>
                                        <line
                                            className='conveyor-belt'
                                            x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                            x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                            y1={i * GRID_ELEMENT_SIZE}
                                            y2={i * GRID_ELEMENT_SIZE + GRID_ELEMENT_SIZE}
                                        />
                                        <line
                                            className='conveyor-belt'
                                            x1={(j * GRID_ELEMENT_SIZE) }
                                            x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                            y1={i * GRID_ELEMENT_SIZE + (GRID_ELEMENT_SIZE / 2)}
                                            y2={i * GRID_ELEMENT_SIZE + GRID_ELEMENT_SIZE}
                                        />
                                    </g>
                                );

                                break;
                            default:
                                console.warn("Only ThreeWay Left has been implemented.");
                                break;
                        }

                        break;

                    case BeltType.Junction:
                        let x1 = 0, x2 = 0, y1 = 0, y2 = 0;
                        const actualRotation = getActualRotation(currentNode, rotation);

                        switch (actualRotation) {
                            case RotationType.TopRight:
                                x1 = (j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2);
                                x2 = (j * GRID_ELEMENT_SIZE) + GRID_ELEMENT_SIZE;
                                y1 = (i * GRID_ELEMENT_SIZE);
                                y2 = (i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2);
                                break;
                            case RotationType.BottomRight:
                                x1 = (j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2);
                                x2 = (j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE);
                                y1 = (i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE);
                                y2 = (i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2);
                                break;
                            case RotationType.BottomLeft: 
                                x1 = (j * GRID_ELEMENT_SIZE);
                                x2 = (j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2);
                                y1 = (i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2);
                                y2 = (i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE);
                                break;
                            case RotationType.TopLeft:
                                x1 = (j * GRID_ELEMENT_SIZE);
                                x2 = (j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2);
                                y1 = (i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2);
                                y2 = (i * GRID_ELEMENT_SIZE);
                                break;                            
                            default:
                        };

                        let className = "conveyor-junction";
                        if (currentNode.lockedRotation) {
                            className = `${className} locked`;
                        }

                        elements.push(                        
                            <line
                                className={className}
                                key={`conveyor-item-${j}-${i}`}
                                x1={x1}
                                x2={x2}
                                y1={y1}
                                y2={y2}
                            />
                        )
                        break;
                    case BeltType.BrokenVertical:
                        elements.push(
                            <line
                                className="conveyor-belt broken"
                                key={`conveyor-item-${j}-${i}`}
                                x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                y1={i * GRID_ELEMENT_SIZE}
                                y2={i * GRID_ELEMENT_SIZE + GRID_ELEMENT_SIZE}
                            />
                        )
                        break;
                    case BeltType.BrokenJunction:
                        elements.push(
                            <line
                                className={"conveyor-belt broken"}
                                key={`conveyor-item-${j}-${i}`}

                            x1={(j * GRID_ELEMENT_SIZE)}
                            x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                            y1={(i * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2)}
                            y2={(i * GRID_ELEMENT_SIZE)}
                            />
                        );
                        break;
                    case BeltType.VerticalSpecial:
                            elements.push(
                                <g key={`conveyor-item-${j}-${i}`}>
                                <line
                                    className='conveyor-belt broken'
                                    x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE) }
                                    y1={(i * GRID_ELEMENT_SIZE) + GRID_ELEMENT_SIZE}
                                    y2={i * GRID_ELEMENT_SIZE + (GRID_ELEMENT_SIZE / 2)}
                                />
                                <line
                                    className='conveyor-belt'
                                    x1={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                    x2={(j * GRID_ELEMENT_SIZE) + (GRID_ELEMENT_SIZE / 2) }
                                    y1={i * GRID_ELEMENT_SIZE}
                                    y2={i * GRID_ELEMENT_SIZE + GRID_ELEMENT_SIZE}
                                />
                            </g>
                            );
                        break;
                    case BeltType.Location:
                        if (currentNode.location) {
                            elements.push(<LocationViews type={currentNode.location!} x={j} y={i}/>);
                        } else {
                            console.warn("WARNING - Location node was specified with no location type");
                        }
                        break;
                    case BeltType.Empty:
                        break;
                    default:
                        // Do nothing
                        console.warn(`Unknown belt type ${currentNode.beltType}`);
                        break;
                }
            }
        }

        return elements;
    }

    return (
        <div style={{ height: 100 }}>
            <div>                
                <button onClick={() => dispatch(configDirection(true))}>Forward</button>
                <button onClick={() => dispatch(configDirection(false))}>Backward</button>
                <button onClick={() => dispatch(configRotation())}>Rotate</button>
                <div>
                    Current Direction: { config.isDirectionForward ? "Forward (Belts go Right and Down)" : "Backward (Belts go Left and Up)"}
                </div>
            </div>
            <div>
                <button onClick={() => dispatch(configToggleGrid())}>Toggle Grid</button>
                <button onClick={() => dispatch(configToggleIsActive())}>{ config.isActive ? "Pause" : "Resume"}</button>
                <button onClick={() => dispatch(resetItems())}>Reset</button>
            </div>

            <div style={{ position: 'relative'}}>
                <svg xmlns="<http://www.w3.org/2000/svg>" height={800} width={600}>
                    {renderBeltGrid(config.rotation)}
                    { beltItems.map(item => <BeltItemView item={item}/> )}
                    { hazards.map(hazard => <BeltHazardView hazard={hazard} /> )}
                </svg>
            </div>
        </div>
    );
};