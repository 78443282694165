import { BeltGridNode, BeltType, LocationType, RotationType, SubType } from "./redux/types";

export const GRID_ELEMENT_SIZE = 50;

export const BeltGrid: BeltGridNode[][] =
[
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Location, location: LocationType.Vault },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.BrokenVertical },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.TerminusTop },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.BrokenVertical },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Vertical },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.TerminusTop },
        { beltType: BeltType.BrokenVertical },
        { beltType: BeltType.Junction, lockedRotation: RotationType.BottomRight },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Junction, subType: SubType.Top},
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.TerminusRight },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Vertical },
        { beltType: BeltType.BrokenVertical },
        { beltType: BeltType.Vertical },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.TerminusTop },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.VerticalSpecial},
        { beltType: BeltType.BrokenJunction },
        { beltType: BeltType.Junction },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Junction },
        { beltType: BeltType.TerminusRight },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Vertical },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Vertical },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Vertical },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.TerminusLeft },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Junction },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.ThreeWay, subType: SubType.Left },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Junction },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Junction },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.TerminusBottom },
        { beltType: BeltType.Empty },
        { beltType: BeltType.TerminusBottom },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Vertical },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Vertical },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
    [
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Location, location: LocationType.Office },
        { beltType: BeltType.Horizontal},
        { beltType: BeltType.Junction, lockedRotation: RotationType.TopLeft },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Horizontal },
        { beltType: BeltType.Location, location: LocationType.LoadingDock },
        { beltType: BeltType.Empty },
        { beltType: BeltType.Empty }
    ],
];