import { GRID_ELEMENT_SIZE } from "../constants";
import { BeltHazard, HazardType } from "../redux/types";

// This import style works as an artifact of using the Create React App, if
// we need to eject, look into https://react-svgr.com/ with webpack
import { ReactComponent as Broken } from '../resources/noun-broken-3968625.svg';
import { ReactComponent as Fire } from '../resources/noun-flame-755585.svg';

export const BeltHazardView = ({ hazard }: { hazard: BeltHazard }) => {
    if (!hazard.isFixed) {        
        if (hazard.hazardType === HazardType.Broken) {
            return <Broken x={hazard.x * GRID_ELEMENT_SIZE} y={hazard.y * GRID_ELEMENT_SIZE} width={GRID_ELEMENT_SIZE} height={GRID_ELEMENT_SIZE} fill="orange" />;
        } else if (hazard.hazardType === HazardType.Fire) {
            return <Fire x={hazard.x * GRID_ELEMENT_SIZE} y={hazard.y * GRID_ELEMENT_SIZE} width={GRID_ELEMENT_SIZE} height={GRID_ELEMENT_SIZE} fill="orange" />;
        }
    }
    
    return <g/>;
}