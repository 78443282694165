import { createSlice } from '@reduxjs/toolkit';
import { Configuration, RotationType } from "./types";

const initialState: Configuration = {
    isActive: false,
    isDirectionForward: true,
    intervalTimeMs: 500,
    rotation: RotationType.TopRight,
    showGrid: true
}

const configurationSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        configToggleIsActive(state: Configuration) {
            state.isActive = !state.isActive
        },
        configDirection(state: Configuration, action: { type:string, payload: boolean }) {
            state.isDirectionForward = action.payload;
        },
        configRotation(state: Configuration) {
            state.rotation = (state.rotation + 1) % 4;
        },
        configToggleGrid(state: Configuration) {
            state.showGrid = !state.showGrid;
        }
    }
});

export const { configToggleIsActive, configDirection, configRotation, configToggleGrid } = configurationSlice.actions;
export default configurationSlice.reducer;